import Head from 'next/head';

import HeroSection from '../components/landing/HeroSection';
import LandingShell from '../components/landing/LandingShell';

export default function LandingPage() {
  return (
    <LandingShell>
      <Head>
        <title>Margins.online: Welcome</title>
      </Head>
      <HeroSection />
    </LandingShell>
  );
}
